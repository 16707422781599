import React from 'react';
import 'flag-icon-css/css/flag-icons.min.css';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';


ReactDOM.render(
    <App />,
  document.getElementById('root')
);