import React from 'react';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps, useTheme } from '@mui/material/styles';
import { textPrimaryLight, textPrimaryDark } from '../../common';

// Constants
const GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=com.lokidev.coupons.app";
const APP_STORE_LINK = "https://apps.apple.com/us/app/coupons-by-lokidev/id6553964089?itsct=apps_box_badge&amp;itscg=30200";

export const COLORS = {
  backgroundColorLight: 'rgba(220, 198, 240, 0.5)',
  backgroundColorDark: 'rgba(125, 86, 178, 0.7)',
  textPrimaryLight: textPrimaryLight,
  textPrimaryDark: textPrimaryDark,
};



const getTextColor = (isDarkMode: boolean) => ({
  color: isDarkMode ? COLORS.textPrimaryDark : COLORS.textPrimaryLight,
});

const FONT_SIZES = {
  titleMobile: '1.9rem',
  titleDesktop: '1.2rem',
};

const getTitleFontSize = (isMobile: boolean) => ({
  title: isMobile ? FONT_SIZES.titleMobile : FONT_SIZES.titleDesktop,
});

const FONT_FAMILY = {
  title: 'Poppins, sans-serif',
};

// Functions for badge size based on device type
const getBadgeSize = () => ({
  width: '150px',
  height: '50px',
});

const getImageStyle = () => ({
  borderRadius: '5px',
  width: getBadgeSize().width,
  height: getBadgeSize().height,
  margin: '5px',
});

// Define types for DownloadBadge props
interface DownloadBadgeProps {
  href: string;
  src: string;
  alt: string;
  isMobile: boolean;
}

// Reusable Badge component with typed props
const DownloadBadge: React.FC<DownloadBadgeProps> = ({ href, src, alt, isMobile }) => (
  <Link href={href} target="_blank" sx={{ display: 'inline-block', overflow: 'hidden', borderRadius: '5px' }}>
    <img
      loading="lazy"
      src={src}
      alt={alt}
      style={getImageStyle()}
    />
  </Link>
);

export type DownloadNowProps = {
  sx?: SxProps;
};

export const DownloadNow: React.FC<DownloadNowProps> = ({ sx }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  
  const getBgColor = (isDarkMode: boolean) => ({
    backgroundColor: isDarkMode ? COLORS.backgroundColorDark : COLORS.backgroundColorLight,
  });

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <Box
        sx={{
          p: 2,
          borderRadius: 2,
          backgroundColor: getBgColor(isDarkMode).backgroundColor,
          mt: { md: 4 },
          textAlign: 'center',
        }}
      >
        <Typography
          gutterBottom
          sx={{
            textAlign: 'center',
            fontSize: getTitleFontSize(isMobile).title,
            fontFamily: FONT_FAMILY.title,
            color: getTextColor(isDarkMode).color,
          }}
        >
          {t('downloadNow!')}
        </Typography>

        {/* Download Badges */}
        <DownloadBadge
          href={GOOGLE_PLAY_LINK}
          src="/images/android_badge.png"
          alt="Download on Google Play"
          isMobile={isMobile}
        />
        <DownloadBadge
          href={APP_STORE_LINK}
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1722643200"
          alt="Download on the App Store"
          isMobile={isMobile}
        />
      </Box>
    </Box>
  );
};

export default DownloadNow;