import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Divider, useScrollTrigger, useMediaQuery } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import MenuComponent from '../MenuComponent/MenuComponent';
import WaveSvg from '../Wave/Wave';
import { textPrimaryDark, textPrimaryLight } from '../../common';

interface CouponsHeaderProps {
  onThemeChange: () => void;
  darkMode: boolean;
}

const LIGHT_BG_GRADIENT = 'linear-gradient(to bottom, #FBD6D2, #D6B0E0)';
const DARK_BG_GRADIENT = 'linear-gradient(to bottom, #3B185F, #6A0572)';
export const LIGHT_TITLE_COLOR = textPrimaryLight;
export const DARK_TITLE_COLOR = textPrimaryDark;
const LOGO_SIZE_MOBILE = 60;
const LOGO_SIZE_DESKTOP = 80;
const TITLE_FONT_SIZE_MOBILE = '3rem';
const TITLE_FONT_SIZE_DESKTOP = '5rem';
const TITLE_FONT_FAMILY = 'Dancing Script';
const COMPANY_NAME_FONT_SIZE = '0.9rem';

function ElevationScroll(props: { children: React.ReactElement; window?: () => Window; }) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 8 : 0,
  });
}

const CouponsHeader: React.FC<CouponsHeaderProps> = ({ onThemeChange, darkMode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getTitle = () => t('couponsApp');
  const getLogoPath = () => '/images/logo_coupons.webp';
  const bgColor = darkMode ? DARK_BG_GRADIENT : LIGHT_BG_GRADIENT;
  const titleColor = darkMode ? DARK_TITLE_COLOR : LIGHT_TITLE_COLOR;
  
  // Transition glow color based on theme
  const glowColor = darkMode ? 'rgba(93, 116, 130, 1)' : 'rgba(255, 182, 93, 0.5)';

  return (
    <ElevationScroll>
      <AppBar position="sticky" sx={{ background: bgColor, overflow: 'hidden' }} elevation={0}>
      <Toolbar
        sx={{
          padding: isMobile ? 0 : 2,
          paddingTop: isMobile ? 3 : 2,
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          gap: isMobile ? 0 : 2,
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        {!isMobile && <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.primary,
            mr: 2,
            alignSelf: 'flex-start',
            fontSize: COMPANY_NAME_FONT_SIZE,
            fontWeight: 'bold',
            letterSpacing: 1,
          }}
        >
          {t('companyName')}
        </Typography>}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{
              color: titleColor,
              fontSize: { xs: TITLE_FONT_SIZE_MOBILE, md: TITLE_FONT_SIZE_DESKTOP },
              fontFamily: TITLE_FONT_FAMILY,
              pt: { xs: 0, md: 0 },
              textAlign: 'center',
              transition: 'box-shadow 2s ease',
            }}
          >
            {getTitle()}
          </Typography>
          {getLogoPath() && (
            <Box
              component="img"
              src={getLogoPath()}
              alt="Logo"
              sx={{
                height: isMobile ? LOGO_SIZE_MOBILE : LOGO_SIZE_DESKTOP,
                width: isMobile ? LOGO_SIZE_MOBILE : LOGO_SIZE_DESKTOP,
                backgroundColor: 'white',
                borderRadius: '50%',
                boxShadow: `0 0 15px 10px ${glowColor}`,
                transition: 'box-shadow 2s ease',
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: isMobile ? 0: 1,
            mt: { xs: 1, md: 0 },
          }}
        >
          <MenuComponent />
          <Divider orientation="vertical" flexItem sx={{ mx: 1, height: 34, borderColor: 'gray', margin: 0, padding: 0, alignSelf: 'center' }} />
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-around' }}>
            <IconButton
              aria-label="Switch theme"
              onClick={onThemeChange}
              sx={{
                color: titleColor,
              }}
            >
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <LanguageSelector />
          </Box>
        </Box>
      </Toolbar>

      <WaveSvg primaryColor={darkMode ? '#6A0572' : '#FBD6D2'} secondaryColor={darkMode ? '#3B185F' : '#D6B0E0'} />
    </AppBar>
    </ElevationScroll>
  );
};

export default CouponsHeader;