import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/translation.json';
import de from './locales/de/translation.json';
import es from './locales/es/translation.json';
import fr from './locales/fr/translation.json';
import it from './locales/it/translation.json';
import ru from './locales/ru/translation.json';
import tr from './locales/tr/translation.json';
import pt from './locales/pt/translation.json';
import ko from './locales/ko/translation.json';
import ja from './locales/ja/translation.json';

// const translationEN = await import('./locales/en/translation.json');
//   const translationDE = await import('./locales/de/translation.json');
//   const translationES = await import('./locales/es/translation.json');
//   const translationFR = await import('./locales/fr/translation.json');
//   const translationIT = await import('./locales/it/translation.json');
//   const translationJA = await import('./locales/ja/translation.json');
//   const translationKO = await import('./locales/ko/translation.json');
//   const translationPT = await import('./locales/pt/translation.json');
//   const translationRU = await import('./locales/ru/translation.json');
//   const translationTR = await import('./locales/tr/translation.json');
  
  i18n
.use(initReactI18next).init({
  resources: {
    en: {
      // translation: translationEN.default
      translation: en
    },
    de:{
      // translation: translationDE.default
      translation: de
    },
    es:{
      // translation: translationES.default
      translation: es
    },
    fr:{
      // translation: translationFR.default
      translation: fr
    },
    it:{
      // translation: translationIT.default
      translation: it
    },
    ja:{
      // translation: translationJA.default
      translation: ja
    },
    ko:{
      // translation: translationKO.default
      translation: ko
    },
    pt:{
      // translation: translationPT.default
      translation: pt
    },
    ru:{
      // translation: translationRU.default
      translation: ru
    },
    tr:{
      // translation: translationTR.default
      translation: tr
    }
  },

  fallbackLng: "en",
  detection: {
    order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    caches: ['cookie'],
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;