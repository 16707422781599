import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as WaveSvgFile } from './wave.svg';

interface WaveSvgProps {
  primaryColor?: string;
  secondaryColor?: string;
}

const WaveSvg: React.FC<WaveSvgProps> = ({ primaryColor, secondaryColor }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Define color variables with a smooth transition effect
  const computedPrimaryColor = primaryColor || (isDarkMode ? '#333333' : 'lightgreen');
  const computedSecondaryColor = secondaryColor || (isDarkMode ? '#222222' : '#EEEEEE');

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', lineHeight: 0, position: 'absolute', bottom: 0, zIndex: -1 }}>
      <WaveSvgFile
        style={{
          width: isMobile ? '100%' : '100%',
          height: isMobile ? '50px' : '150px',
        }}
        className="wave"
      />
      <style>
        {`
          .wave .primaryColor { 
            fill: ${computedPrimaryColor}; 
            transition: fill 2s ease;
          }
          .wave .secondaryColor { 
            fill: ${computedSecondaryColor}; 
            transition: fill 2s ease;
          }
        `}
      </style>
    </Box>
  );
};

export default WaveSvg;