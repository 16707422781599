import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Divider, useScrollTrigger, useMediaQuery } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import MenuComponent from '../MenuComponent/MenuComponent';
import { motion } from 'framer-motion';
import WaveSvg from '../Wave/Wave';

interface AboutHeaderProps {
  onThemeChange: () => void;
  darkMode: boolean;
}

function ElevationScroll(props: { children: React.ReactElement; window?: () => Window; }) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 8 : 0,
  });
}

const AboutHeader: React.FC<AboutHeaderProps> = ({ onThemeChange, darkMode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();

  const getTitle = () => {
    return t('welcomeTitle');
  };

  const getFontFamily = () => {
    switch (location.pathname) {
      default:
        return 'Dancing Script';
    }
  };

  const getBgColor = () => {
    return darkMode ? '#41424C' : 'white'
  };

  return (
    <ElevationScroll>
      <AppBar position="sticky" sx={{ background: getBgColor(), overflow: 'hidden' }} elevation={0}>
        <Toolbar
          sx={{
            padding: 2,
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'center', md: 'center' },
            gap: 2,
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Typography variant="h6" component="div" sx={{ color: theme.palette.text.primary, mr: 2, alignSelf: 'flex-start', fontSize: {xs: ".8rem", md: '1rem'} }}>
              {t('companyName')}
            </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: { xs: 'row', md: 'row' }, justifyContent: { xs: 'space-between', md: 'space-between' } }}>
            <motion.div style={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: { xs: '2rem', md: '3.5rem' },
                  fontFamily: getFontFamily(),
                  pt: { xs: 4, md: 0 },
                }}
              >
                {getTitle()}
              </Typography>
            </motion.div>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', gap: 1, marginTop: { xs: 0, md: 0 } }}>
                <MenuComponent />
                <Divider orientation="vertical" flexItem sx={{ mx: { xs: 0, sm: 2 }, height: 28, alignSelf: 'center', borderColor: 'gray' }} />
                <IconButton color="inherit" aria-label='Switch theme' onClick={onThemeChange} sx={{ color: theme.palette.text.primary, marginRight: '15px' }}>
                  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <LanguageSelector />
              </Box>
            </Toolbar>
            <WaveSvg />
          </AppBar>
        </ElevationScroll>
        );
      };
      
      export default AboutHeader;