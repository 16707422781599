import React from 'react';
import { useLocation } from 'react-router-dom';
import CouponsHeader from './CouponsHeader';
import AboutHeader from './AboutHeader';

interface HeaderProps {
  onThemeChange: () => void;
  darkMode: boolean;
}

const Header: React.FC<HeaderProps> = ({ onThemeChange, darkMode }) => {
  const location = useLocation();
  const isCouponsApp = location.pathname.includes('/coupons');

  return isCouponsApp ? <CouponsHeader onThemeChange={onThemeChange} darkMode={darkMode} /> : <AboutHeader onThemeChange={onThemeChange} darkMode={darkMode} />; 
};
      
export default Header;