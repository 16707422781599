import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { supportEmail } from '../../common';
import { useLocation } from 'react-router-dom';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  
  const isDarkMode = theme.palette.mode === 'dark';

  const getBgColor = () => {
    switch (location.pathname) {
      case '/coupons/about':
      case '/coupons/terms-of-use':
      case '/coupons/privacy-policy':
      case '/coupons/account-deletion':
        return isDarkMode
        ? 'rgba(0, 0, 0, 0.5)'  // black with 50% opacity
        : 'rgba(255, 255, 255, 0.5)';  // light blue with 50% opacity
      default:
        return isDarkMode ? 'rgba(66, 66, 66, 0.5)' : 'rgba(255, 255, 255, 0.5)';  // gray or white with 50% opacity
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        py: 1,
        px: 2,
        backgroundColor: getBgColor(),
        color: 'white',
        zIndex: 1,
      }}
    >
      <Typography variant="body1" sx={{ color: isDarkMode ? 'white' : 'black', textWrap: 'pretty', textAlign: 'center' }}>
        {t('contactInfo')}{' '}
        <Link href={`mailto:${supportEmail}`} sx={{ textDecoration: 'underline', color: 'inherit' }}>
          {supportEmail}
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;