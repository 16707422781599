import React from 'react';
import { Container, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BACKGROUND_DARK, BACKGROUND_LIGHT, textPrimaryDark, textPrimaryLight } from '../../common';
import DownloadNow from './DownloadNow';

const imageUrl = '/images/mascot_kitten_web.webp';

const IMAGE_STYLE = {
  borderRadius: '50%',
  padding: '10px',
};

const OpenAppPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const darkMode = theme.palette.mode === 'dark';
  const opacity = darkMode ? 0.5 : 0.3;
  const textColor = darkMode ? textPrimaryDark : textPrimaryLight;

  // Check if the URL contains /acceptBook with bookId parameter
  const urlParams = new URLSearchParams(window.location.search);
  const hasBookId = urlParams.has('bookId');

  // Constants for colors and fonts
  const COLORS = {
    panelBackground: `rgba(255, 182, 193, ${opacity})`,
    appBackground: `rgba(93, 116, 130, ${opacity})`,
  };
  
  const FONT_FAMILY = {
    title: '"Playwrite GB J", cursive',
  };
  
  const FONT_SIZES = {
    title: '2rem',
    titleBig: '2.5rem',
    text: '1rem',
    textBig: '1.25rem',
  };
  
  const getTitleFontSize = (isMobile: boolean) => isMobile ? FONT_SIZES.title : FONT_SIZES.titleBig;

  // Select the appropriate download text
  const downloadText = hasBookId ? t('downloadAndComeAgain') : t('downloadCouponsApp');

  return (
    <Box sx={{ position: 'relative', color: textColor, overflow: 'hidden', height: '100vh' }}>
      {/* Layered Backgrounds */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          backgroundImage: `url(${BACKGROUND_LIGHT})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'opacity 0.6s ease',
          opacity: darkMode ? 0 : 1,
          zIndex: -2,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          backgroundImage: `url(${BACKGROUND_DARK})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'opacity 0.6s ease',
          opacity: darkMode ? 1 : 0,
          zIndex: -1,
        }}
      />

      <Container
        sx={{
          pb: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {/* Main Panel */}
        <Box sx={{ flexGrow: 1, maxWidth: { xs: '100%', md: '60%' }, textAlign: 'center' }}>
          {/* Intro Panel */}
          <Box sx={{ my: isMobile ? 2 : 4, p: 2, borderRadius: 2, backgroundColor: COLORS.panelBackground }}>
            <Typography
              variant="h3"
              component="h3"
              gutterBottom
              sx={{
                fontFamily: FONT_FAMILY.title,
                fontSize: getTitleFontSize(isMobile),
              }}
            >
              {t('getStartedWithYourGift!')}
            </Typography>
            <Typography sx={{ fontSize: '1.2rem' }}>{downloadText}</Typography>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                component="img"
                src={imageUrl}
                sx={{ ...IMAGE_STYLE }}
                width={isMobile ? '150px' : '200px'}
                height={isMobile ? '150px' : '200px'}
              />
            </Box>
          </Box>
          <DownloadNow sx={{ width: { xs: '100%', md: '60%' }, minWidth: { xs: '100%', md: '100%' }}} />
        </Box>
      </Container>
    </Box>
  );
};

export default OpenAppPage;