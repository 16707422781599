import React, { useState, useEffect, useRef } from 'react';
import { Button, Menu, MenuItem, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const RotateIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  transition: theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.short,
  }),
  color: theme.palette.text.primary,
}));

const Highlight = styled(motion.div)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
});

const MenuComponent: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isHovered, setIsHovered] = useState<null | number>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => setOpen(true), 300);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsHovered(null);
    setOpen(false);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    handleMenuClose();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (open) {
         setTimeout(() => {
          handleMenuClose();
        }, isMobile ? 0 : 300);
      }
    };

    if (anchorEl) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl, isMobile, open]);
  
  const menuItems = [
    { path: 'about', key: 'about' },
    { path: 'terms-of-use', key: 'termsOfUse' },
    { path: 'privacy-policy', key: 'privacyPolicy' },
    { path: 'account-deletion', key: 'accountDeletion' },
  ];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
      <Button onClick={() => handleNavigate('/')} color="inherit" sx={{ color: theme.palette.text.primary, mr: 0 }}>
        <HomeIcon sx={{ mr: 0 }} />
      </Button>
      <Button
        color="inherit"
        endIcon={<RotateIcon sx={{ color: theme.palette.text.primary, ml: isMobile ? 1 : 2 }} style={{ transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
        onMouseEnter={!isMobile ? handleMenuOpen : undefined}
        onClick={isMobile ? handleMenuOpen : undefined}
        onTouchStart={handleMenuOpen}
        sx={{ color: theme.palette.text.primary, position: 'relative' }}
      >
        {t('couponsApp')}
        {anchorEl && <Highlight layoutId="highlight" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        disableScrollLock
        hideBackdrop
        open={Boolean(anchorEl)}
        MenuListProps={{
          onMouseEnter: !isMobile ? () => setAnchorEl(anchorEl) : undefined,
          onMouseLeave: !isMobile ? handleMenuClose : undefined,
        }}
        ref={menuRef}
      >
        {menuItems.map((item, index) => (
  <MenuItem
    key={item.path}
    onClick={() => handleNavigate(`/coupons/${item.path}`)}
    onMouseEnter={() => setIsHovered(index)}
    onMouseLeave={() => setIsHovered(null)}
    sx={{
      position: 'relative',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }}
  >
    {t(item.key)}
    {isHovered === index && (
      <Highlight
        layoutId="highlight"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />
    )}
  </MenuItem>
))}
      </Menu>
    </Box>
  );
};

export default MenuComponent;